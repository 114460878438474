<script>
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import ControlSpecificPanel from "@/components/editor/control-specific-panel.vue";
import DataStateListForm from "@/components/control-sidebar/property-editors/data-state-list-form.vue";
export default {
  extends: BaseControl,
  components: {
    ControlBaseProperties,
    ControlSpecificPanel,
    DataStateListForm
  },
  data() {
    return {
      enabled: {},
      dataSourceType: "local",
      dataSourceId: "",
      stateImages: [],
      lastData: null
    };
  },
  computed: {
    control: {
      set(value) {
        this.$store.dispatch("synoptic/updateControl", {
          id: value.id,
          control: value,
          noMerge: true
        });
      },
      get() {
        return (
          (this.$store.getters["synoptic/selectedControls"] || [])[0] || null
        );
      }
    },
    defState() {
      const defState = this?.control?.synopticComponent?.default ?? "";
      return (this?.control?.synopticComponent?.stateImages || []).find(
        (i) =>
          defState
            ? i.state == defState
            : String(i.state).toLowerCase() == "default" // legacy
      );
    },
    dataSource: {
      set(value) {
        this.$store.dispatch("synoptic/updateControl", {
          id: this.control.id,
          control: {
            ...this.control,
            synopticComponent: {
              ...this.control.synopticComponent,
              dataSource: value.dataSource,
              stateImages: [...value.items],
              default: value.default
            }
          },
          noMerge: true
        });
      },
      get() {
        return {
          dataSource: this.control?.synopticComponent?.dataSource || {
            id: "",
            type: "local"
          },
          default: this?.defState?.state || "",
          items: this.control?.synopticComponent?.stateImages || []
        };
      }
    },
    equipmentData() {
      if (this.control?.data_id || 0) {
        return (
          (this.$store.getters["dashboard/extendedDataList"] || []).find(
            (i) => i.id == this.control.data_id
          ) || null
        );
      }
      return null;
    }
  },
  watch: {
    equipmentData(n) {
      if (n) {
        this.lastData = {...n};
      }
    }
  }
};
</script>